globalThis.__VUE_OPTIONS_API__ = true;
globalThis.__VUE_PROD_DEVTOOLS__ = true;

import { createApp } from 'vue/dist/vue.esm-bundler.js'

import { Menu as UiMenu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot, Dialog as UiDialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon, Bars3BottomLeftIcon } from '@heroicons/vue/24/outline'

import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

document.addEventListener('DOMContentLoaded', () => {
  const top_nav = createApp()

  top_nav
    .component('uimenu', UiMenu)
    .component('menubutton', MenuButton)
    .component('menuitem', MenuItem)
    .component('menuitems', MenuItems)
    .component('xmarkicon', XMarkIcon)
    .component('popover', Popover)
    .component('popoverbutton', PopoverButton)
    .component('popoverpanel', PopoverPanel)
    .component('popoveroverlay', PopoverOverlay)
    .component('bars3icon', Bars3Icon)
    .component('bellicon', BellIcon)
    .component('transitionchild', TransitionChild)
    .component('transitionroot', TransitionRoot)

  top_nav.mount('#top-nav')

  const side_nav = createApp()

  side_nav
    .component('uimenu', UiMenu)
    .component('menubutton', MenuButton)
    .component('menuitem', MenuItem)
    .component('menuitems', MenuItems)
    .component('xmarkicon', XMarkIcon)
    .component('popover', Popover)
    .component('popoverbutton', PopoverButton)
    .component('popoverpanel', PopoverPanel)
    .component('popoveroverlay', PopoverOverlay)
    .component('bars3icon', Bars3Icon)
    .component('bellicon', BellIcon)
    .component('transitionchild', TransitionChild)
    .component('transitionroot', TransitionRoot)
    .component('uidialog', UiDialog)
    .component('dialogpanel', DialogPanel)
    .component('bars3bottomlefticon', Bars3BottomLeftIcon)

  side_nav.mount('#side-nav')

  const carousel = createApp()

  carousel
    .component('carousel', Carousel)
    .component('slide', Slide)
    .component('navigation', Navigation)

    if ($('#carousel').length > 0) {
      carousel.mount('#carousel')
    }
})
